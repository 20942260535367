import React from "react";
import { Nav, Navbar } from "react-bootstrap"
import "../styles/navbar.css";
import "../styles/search.css";
import logo from '../resource/nkm-logo.png';
import Search from "./Search"

export default function Navigator() {

  return (
  <nav className="navigation">
        <a href="/" className="left brand-logo">
          <img src={logo} className="photo" alt="logo" />
        </a>
  <Navbar
        collapseOnSelect
        expand="md"
        bg="#4B0082"
        variant="dark"
        className="px-4 py-8"
        fixed="top"
      >
        <Navbar.Brand><Search /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-na" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto align-items-end px-4" >
            <Nav.Link >Photo Gallery</Nav.Link>
            <Nav.Link>Video Gallery</Nav.Link>
            <Nav.Link>Matches</Nav.Link>
            <Nav.Link>Teams</Nav.Link>
            <Nav.Link>News</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      </nav>
  );
}
