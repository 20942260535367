import React from 'react';
import { Slide } from 'react-slideshow-image';
import "../styles/gallery.css";
import 'react-slideshow-image/dist/styles.css';

const Gallery = () => {
    const images = [
        {url: './resource/images/hd/nkm-1.jpg'},
        {url: './resource/images/hd/nkm-2.jpg'},
        {url: './resource/images/hd/nkm-3.jpg'},
        {url: './resource/images/hd/nkm-4.jpg'},
        {url: './resource/images/hd/nkm-5.jpg'},
        {url: './resource/images/hd/nkm-6.jpg'},
    ];

    return (
        <Slide>
        {images.map((image, index)=> (
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${image.url})` }}>
                    <span></span>
                </div>
            </div>
            ))}
        </Slide>
    );
};

export default Gallery;